import React from "react";
import { SignupFormView } from "./SignupFormView";
import { observer } from "mobx-react-lite";
import { useMst } from "@app/index";

const SignupFormDomain: React.FunctionComponent = (props) => {
  const { authStore } = useMst();
  return (
    <SignupFormView
      onSubmit={(email) => authStore.createAccount(email)}
      requestStatus={authStore.signupRequestStatus}
    />
  );
};

export default observer(SignupFormDomain);
