import "./PageComponent.scss";

import React, { ReactNode } from "react";

import { Layout } from "antd";

const { Footer, Content } = Layout;

type PageComponentProps = {
  header: ReactNode;
  content: ReactNode;
  footer: ReactNode;
};

export const PageComponent: React.FunctionComponent<PageComponentProps> = ({
  header,
  content,
  footer,
}) => {
  return (
    <Layout>
      <>{header}</>
      <Content>{content}</Content>
      {footer === undefined ? null : <Footer>{footer}</Footer>}
    </Layout>
  );
};
