import React, { FunctionComponent } from "react";
import "./HamburgerIcon.scss";

type HamburgerIconProps = {
  onClick: () => void;
};

export const HamburgerIcon: FunctionComponent<HamburgerIconProps> = ({
  onClick,
}) => {
  return (
    <div className="hamburger-icon" onClick={onClick}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
