import React from "react";
import { Tag } from "antd";

interface StatusTagProps {
  status: string;
}

const STATUS_COLOR_MAPPING: { [status: string]: string } = {
  done: "#87d068",
  committed: "#108ee9",
  half_life_reset: "#eef52d",
};

const STATUS_NAMES_MAPPING: { [status: string]: string } = {
  done: "Done",
  committed: "Focused",
  half_life_reset: "Half-life reset",
};

export const StatusTag: React.FunctionComponent<StatusTagProps> = ({
  status,
}) => {
  const color = STATUS_COLOR_MAPPING[status];

  if (status === "reset") {
    return null;
  }

  return <Tag color={color}>{STATUS_NAMES_MAPPING[status]}</Tag>;
};
