import React, { FunctionComponent } from "react";
import "./EmptyCircle.scss";

type EmptyCircleType = {
  onClick?: () => void;
};

export const EmptyCircle: FunctionComponent<EmptyCircleType> = ({
  onClick,
}) => {
  return <div className="empty-circle" onClick={onClick} />;
};
