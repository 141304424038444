import { autorun } from "mobx"
import { cast, IModelType, IMSTMap, ModelPropertiesDeclaration, ModelPropertiesDeclarationToProperties } from "mobx-state-tree"
import { IRootStore } from "../rootStore"
import { INetworkOperation } from "../systems/network/network-system"

type IdType = {
    id: number
}

export function baseNetworkOpsActions(
    root: IRootStore,
) {
    return {
        trackSuccessOp(operationId: string, onSuccess: (op: INetworkOperation) => void, onError: (op: INetworkOperation) => void = () => { }) {
            const cancel = autorun(() => {
                const op = root.networkSystem.byId.get(operationId)

                if (!op) {
                    return
                }

                if (op.status === 'success') {
                    cancel()
                    return onSuccess(op);
                } else if (op.status === 'error') {
                    console.error(`Op: ${operationId} - ${op.operation} ${op.status} - error`, op.error)
                    cancel()
                    return onError(op);
                }
            })

        }
    }
}

export function baseStoreActions<T extends IModelType<ModelPropertiesDeclarationToProperties<P>, {}>, E extends IdType, P extends ModelPropertiesDeclaration = {}>(
    currentStoreName: string,
    root: IRootStore,
    trackedData: IMSTMap<T>,
) {
    return {
        ...baseNetworkOpsActions(root),
        ...{
            setAll(items: E[]) {
                try {
                    console.time(`${currentStoreName}-setAll`)
                    for (let item of items) {
                        trackedData.set(item.id.toString(), cast(item))
                    }
                    console.timeEnd(`${currentStoreName}-setAll`)
                } catch (e) {
                    console.error(`${currentStoreName}-setAll - items`, items, e)
                    throw e
                }
            },
            set(item: E) {
                try {
                    console.time(`${currentStoreName}-set`)
                    trackedData.set(item.id.toString(), cast(item))
                    console.timeEnd(`${currentStoreName}-set`)
                } catch (e) {
                    console.log(`${currentStoreName}-set`, item, e)
                    throw e
                }
            },
            delete(item: E) {
                this.deleteById(item.id)
            },
            deleteById(id: number) {
                try {
                    console.time(`${currentStoreName}-delete`)
                    trackedData.delete(id.toString())
                    console.timeEnd(`${currentStoreName}-delete`)
                } catch (e) {
                    console.log(`${currentStoreName}-delete`, id, e)
                    throw e
                }
            },
        }
    }
}

export function baseViews() {

}