import { createNetworkOperation, INetworkOperation } from "@systems/network/network-system";
import { Commitment } from "../stores/commitments-store";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export function fetchCommitmentsOp(
    status: string | undefined = undefined
): INetworkOperation {
    const params = new URLSearchParams({
        _sort: "updated_at:DESC",
    });

    if (status !== undefined) {
        params.append("status", status);
    }

    return createNetworkOperation({
        operation: 'getAll',
        entityType: "Commitment",
        entityId: '*',
        url: `${BASE_URL}/commitments?${params}`,
        method: 'GET',
        auth: true,
        body: undefined
    })
}

export function modifyCommitmentOp(
    entityId: number,
    entityType: string,
    operation: string
): INetworkOperation {
    return createNetworkOperation({
        operation: `update`,
        entityType: "Commitment",
        entityId: entityId,
        url: `${BASE_URL}/${entityType}/${entityId}/${operation}`,
        method: 'POST',
        auth: true,
        body: {}
    })
}