import React, { useEffect } from "react";
import Typography from "antd/lib/typography";

import { Button, Input } from "antd";
import { Link } from "react-router-dom";
import { InjectedSearchProps } from "./SearchDomain";
import "./SearchView.scss";
import { SearchEntityType } from "@systems/search/search-system";

const { Search } = Input;

// TODO move me
const urlHelper = (mtdv_id: string, type: SearchEntityType) => {
  if (type === "Post") {
    return `/library/posts/${mtdv_id}`;
  } else if (type === "Collection") {
    return `/library/collections/${mtdv_id}`;
  }

  return "/library";
};

const SearchView: React.FunctionComponent<InjectedSearchProps> = ({
  onSearch,
  onClearSearch,
  searchResults,
  searchPattern,
}) => {
  useEffect(() => {
    onClearSearch();
  }, []);

  return (
    <div className="search-view">
      <div className="search-bar">
        <Search
          placeholder="Search Metadevelopment Content library"
          onChange={(e) => onSearch(e.target.value)}
        />
      </div>

      {searchResults.length > 0 ? (
        <div className={"search-results"}>
          <Typography.Title level={4}>Search results</Typography.Title>
          <div>
            {searchResults.map((c) => {
              return (
                <div className={"search-results__item"}>
                  <Link to={urlHelper(c.mtdv_id, c.type)}>{c.title}</Link>
                </div>
              );
            })}
          </div>
        </div>
      ) : !!searchPattern ? (
        <div className={"search-no-results"}>
          <h1>No results</h1>
          <Button onClick={() => onClearSearch()}>Reset search</Button>
        </div>
      ) : null}
    </div>
  );
};

export default SearchView;
