import React, { useState } from "react";
import { Form, Input, Button, Checkbox, Typography } from "antd";
import { UserOutlined, CheckCircleTwoTone } from "@ant-design/icons";

interface LoginFormProps {
  onSubmit: (email: string) => void;
  requestStatus: string;
}

export const SignupFormView: React.FunctionComponent<LoginFormProps> = ({
  onSubmit,
  requestStatus,
}) => {
  const [email, setEmail] = useState<string | undefined>(undefined);

  const handleEmailChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => setEmail(e.target.value);

  const handleLogin = () => {
    if (email !== undefined) {
      onSubmit(email);
    }
  };

  return (
    <div className="register-form">
      {requestStatus === "SUCCESS" ? (
        <>
          <div className="register-form__icon">
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          </div>
          <Typography.Title level={4}>
            <div>
              Signup successful! We have just sent you an email to{" "}
              <b>{email}</b>, click on the link to log in to proceed.
            </div>
          </Typography.Title>
        </>
      ) : (
          <>
            <Typography.Title level={2}>
              Create Metadevelopment Account
          </Typography.Title>
            <Typography.Paragraph className="register-form__description">
              <div>Enter your email, we will send you a magic link to login</div>
            </Typography.Paragraph>

            <Form
              name="signup"
              className="signup-form"
              initialValues={{
                remember: true,
              }}
              onFinish={handleLogin}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter your email",
                  },
                ]}
              >
                <Input
                  type="email"
                  size="large"
                  onChange={handleEmailChange}
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                />
              </Form.Item>

              <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>
                    I want to receive latest updates from MTDV Newsletter
                </Checkbox>
                </Form.Item>
              </Form.Item>

              <Form.Item>
                <Button
                  disabled={!!email}
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  Sign up
              </Button>
              </Form.Item>

              <Form.Item>
                <Typography.Link href={"/login"}>
                  {"Already have account? Login here"}
                </Typography.Link>
              </Form.Item>
            </Form>
          </>
        )}

      {requestStatus === "IN_PROGRESS" ? (
        <Typography.Title level={3}>Creating account <span role="img" aria-label="clock">⏳</span></Typography.Title>
      ) : null}

      {requestStatus === "ERROR" ? (
        <Typography.Title level={3}>
          Could not create account, please try one more time or contact us
          <a href="mailto:hello@mtdv.io">hello@mtdv.io</a>
        </Typography.Title>
      ) : null}
    </div>
  );
};
