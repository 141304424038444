import React from "react";

import { CollectionDetails } from "@modules/content/components/collection-details/CollectionDetails";
import { useParams } from "react-router";

import "./CollectionDetailsScreen.scss";

type CollectionDetailsScreenRouteParams = {
  id: string;
};

const CollectionDetailsScreen = () => {
  const { id } = useParams<CollectionDetailsScreenRouteParams>();

  return (
    <div className="collection-details-screen">
      <CollectionDetails collectionMtdvId={id} />
    </div>
  );
};

export default CollectionDetailsScreen;
