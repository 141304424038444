import React, { ReactNode } from "react";
// import Masonry from 'react-masonry-css'
import Masonry from "react-masonry-component";

import "./MtdvMarsonyGrid.scss";

type MarsonyGridProps = {
  children: ReactNode[];
};

const masonryOptions = {
  transitionDuration: 0,
};

export const MtdvMarsonyGrid: React.FunctionComponent<MarsonyGridProps> = ({
  children,
}) => {
  return (
    <Masonry
      className="mtdv-masonry-grid"
      options={masonryOptions} // default {}
      disableImagesLoaded={false} // default false
      updateOnEachImageLoad={false}
    >
      {children}
    </Masonry>
  );
};
