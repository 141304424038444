import { createNetworkOperation, INetworkOperation } from "@systems/network/network-system";
import { Collection } from "../stores/collections-store";
import { Post } from "../stores/posts-store";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export function fetchPostsOp(): INetworkOperation {
    const params = new URLSearchParams({
        _limit: "500",
    });

    return createNetworkOperation({
        operation: `getAll`,
        entityType: `Post`,
        entityId: '*',
        url: `${BASE_URL}/posts?${params}`,
        method: 'GET',
        auth: true,
        body: undefined
    })
}

export function fetchCollectionsOp(): INetworkOperation {
    const params = new URLSearchParams({
        _limit: "500",
    });

    return createNetworkOperation({
        operation: 'getAll',
        entityType: `Collection`,
        entityId: '*',
        url: `${BASE_URL}/collections?${params}`,
        method: 'GET',
        auth: true,
        body: undefined
    })
}