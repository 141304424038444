import React from "react";
import classnames from "classnames";
import { Card } from "antd";
import { Link } from "react-router-dom";
import { StatusDate } from "@components/common/status-date/StatusDate";
import { ICommitment } from "@modules/focus/stores/commitments-store";
import { observer } from "mobx-react-lite";

import "./FocusPostCard.scss";
import { useMst } from "@app/index";

interface FocusPostCardViewProps {
  id: ICommitment['id'];
}

const Component: React.FunctionComponent<FocusPostCardViewProps> = ({
  id,
}) => {
  const { commitments } = useMst()
  const post = commitments.postById(id)
  if (!post) return null

  const cls = classnames("focus-post-card");

  return (
    <Card
      className={cls}
      title={
        <span>
          <Link to={`/library/posts/${post?.mtdv_id}`}>{post?.title}</Link>
        </span>
      }
      cover={<div style={{ width: 150, height: 60 }}></div>}
      actions={
        [
          // icon
        ]
      }
    >
      <StatusDate id={id} />
    </Card>
  );
};

export const FocusPostCardView = observer(Component)