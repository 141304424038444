import React, { FunctionComponent } from "react";
// @ts-ignore
import { ReactTinyLink } from "react-tiny-link";

type PreviewLinkProps = {
  link: string;
  size?: string;
  showGraphic?: boolean;
};

export const PreviewLink: FunctionComponent<PreviewLinkProps> = ({
  link,
  size = "large",
  showGraphic = true,
}) => {
  return (
    // @ts-ignore
    <ReactTinyLink
      // TODO proxyUrl={"implement me on server!!!"}
      cardSize={size}
      showGraphic={showGraphic}
      maxLine={4}
      minLine={1}
      proxyUrl={"https://cors-proxy.mtdv.workers.dev?"}
      url={link}
    />
  );
};
