import { RawEntityId, RawOperations } from "@systems/network/network-system";
import { Skeleton, Alert } from "antd";
import { observer } from "mobx-react-lite";
import React, { ReactElement } from "react";
import { useMst } from "..";

type NetworkProgressParams = {
    entityType: string,
    entityId?: RawEntityId,
    operation?: RawOperations,

    success: ReactElement,
    progress?: ReactElement,
    error?: ReactElement
};

const NetworkProgress: React.FunctionComponent<NetworkProgressParams> = ({
    entityType,
    operation,
    entityId,

    success,
    progress,
    error
}) => {
    const { networkSystem } = useMst()
    const maybeOp = networkSystem.findBy(entityType, entityId, operation)

    if (!maybeOp) {
        return null;
    }

    if (maybeOp.status === "in_progress" || maybeOp.status === "idle") {
        return progress || <Skeleton active />
    } else if (maybeOp.status === "success") {
        return success
    } else if (maybeOp.status === "error") {
        return error || <Alert
            type="error"
            message="Ops something went wrong. Please reload the page."
            banner
        />
    }

    return null;
};

export default observer(NetworkProgress);
