import React from "react";
import {
  Switch,
  Route,
} from "react-router-dom";
import "./App.scss";

import FocusScreen from "@screens/focus/FocusScreen";
import SignupScreen from "@screens/signup/SignupScreen";
import LoginScreen from "@screens/login/LoginScreen";
import PrivateRoute from "@components/PrivateRoute";
import { observer } from "mobx-react-lite";
import { useMst } from "./index";
import { Typography } from "antd";
import { PageComponent } from "@components/structure/PageComponent";
import LibraryScreen from "@screens/library/LibraryScreen";
import CollectionDetailsScreen from "@screens/collection-details/CollectionDetailsScreen";
import PostDetailsScreen from "@screens/post-details/PostDetailsScreen";
import NavigationView from "@components/navigation/NavigationView";
import SettingsScreen from "@screens/settings/SettingsScreen";
import ToolsScreen from "@screens/tools/ToolsScreen";

function App() {
  const { authStore } = useMst()
  const router = (
    <Switch>
      <Route path="/login">
        <PageComponent header={null} content={<LoginScreen />} footer={null} />
      </Route>
      <Route path="/register">
        <PageComponent header={null} content={<SignupScreen />} footer={null} />
      </Route>
      <PrivateRoute path="/library/collections/:id">
        <PageComponent
          header={<NavigationView />}
          content={<CollectionDetailsScreen />}
          footer={null}
        />
      </PrivateRoute>
      <PrivateRoute path="/library/posts/:id">
        <PageComponent
          header={<NavigationView />}
          content={<PostDetailsScreen />}
          footer={null}
        />
      </PrivateRoute>

      <PrivateRoute path="/resources">
        <PageComponent
          header={<NavigationView />}
          content={<LibraryScreen />}
          footer={null}
        />
      </PrivateRoute>
      <PrivateRoute path="/courses">
        <PageComponent
          header={<NavigationView />}
          content={<LibraryScreen />}
          footer={null}
        />
      </PrivateRoute>
      <PrivateRoute path="/quests">
        <PageComponent
          header={<NavigationView />}
          content={<LibraryScreen />}
          footer={null}
        />
      </PrivateRoute>
      <PrivateRoute path="/tools">
        <PageComponent
          header={<NavigationView />}
          content={<ToolsScreen />}
          footer={null}
        />
      </PrivateRoute>

      <PrivateRoute path="/settings">
        <PageComponent
          header={<NavigationView />}
          content={<SettingsScreen />}
          footer={null}
        />
      </PrivateRoute>
      <PrivateRoute path="/">
        <PageComponent
          header={<NavigationView />}
          content={<FocusScreen />}
          footer={null}
        />
      </PrivateRoute>
    </Switch>
  );

  const isLoadingComponent = (
    <div className="app-init-state">
      <Typography.Title level={2}>
        App is loading, wait a sec <span role="img" aria-label="rocket">🚀</span>
      </Typography.Title>
    </div>
  );

  return authStore.isInitComplete ? router : isLoadingComponent;
}

export default observer(App);
