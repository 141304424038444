import React from "react";
import { Empty, PageHeader, Typography } from "antd";
import { Stack } from "@components/structure/Stack";

import { StatusTag } from "@components/common/status-tag/StatusTag";
import { StatusActionButton } from "@components/common/status-button/StatusActionButton";
import { observer } from "mobx-react-lite";

import "./CollectionDetails.scss";
import { useMst } from "@app/index";
import { CollectionPosts } from "../collection-card/CollectionPosts";

type CollectionDetailsProps = {
  collectionMtdvId: string
}

// TODO we should have separate emoty state components, good task for Valik
const emptyState = (
  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}>
    Not found such Collection
  </Empty>
);

const Component: React.FunctionComponent<CollectionDetailsProps> = ({
  collectionMtdvId,
}) => {
  const { collections, commitments } = useMst()

  const collection = collections.byMtdvId[collectionMtdvId]

  if (collection === undefined) {
    return emptyState;
  }

  const commitment = commitments.byCollection[collection.id];
  const hasFocusSlots = commitments.hasFocusSlots;

  let subTitle: {} | null | undefined = null;
  if (commitment !== undefined) {
    subTitle = <StatusTag status={commitment.status} />;
  }

  return (
    <div className="collection-details">
      <Stack>
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title={`${collection.title}`}
          subTitle={subTitle}
          style={{ backgroundColor: collection.preview_colour }}
          extra={[
            <StatusActionButton
              commitment={commitment}
              hasFocusSlots={hasFocusSlots}
              onCommit={() => collections.commit(collection.id)}
              onFinish={() => collections.finish(collection.id)}
              onReset={() => collections.reset(collection.id)}
            />,
          ]}
        />

        <div className="page-component">
          <Typography.Title level={4}>
            Learn {collection.title} with Posts:
          </Typography.Title>
          <CollectionPosts collectionId={collection.id} />
        </div>
      </Stack>
    </div>
  );
};

export const CollectionDetails = observer(Component)
