import React, { useState } from "react";
import { Form, Input, Button, Typography } from "antd";
import { UserOutlined, CheckCircleTwoTone } from "@ant-design/icons";

import "./LoginScreen.scss";

interface LoginFormProps {
  onSubmit: (email: string) => void;
  requestStatus: string;
}

export const LoginFormView: React.FunctionComponent<LoginFormProps> = ({
  onSubmit,
  requestStatus,
}) => {
  const [email, setEmail] = useState<string | undefined>(undefined);

  const handleEmailChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => setEmail(e.target.value);

  const handleLogin = () => {
    if (email !== undefined) {
      onSubmit(email);
    }
  };

  return (
    <div className="login-form">
      {requestStatus === "SUCCESS" ? (
        <>
          <div className="login-form__icon">
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          </div>
          <Typography.Title level={4}>
            <div>
              We have just sent you an email to <b>{email}</b>, click on the
              magic link in your inbox to log in.
            </div>
          </Typography.Title>
          <div>
            <Typography.Link href={"/register"}>
              {"Don't have an account? Sign Up here"}
            </Typography.Link>
          </div>
        </>
      ) : (
          <>
            <Typography.Title level={2}>Login to MTDV App</Typography.Title>
            <Typography.Paragraph className="login-form__description">
              <div>Enter your email, we will send you a magic link to login</div>
            </Typography.Paragraph>

            <Typography.Paragraph className="login-form__description"></Typography.Paragraph>

            <Form
              name="normal_login"
              initialValues={{
                remember: true,
              }}
              onFinish={handleLogin}
            >
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please enter your email",
                  },
                ]}
              >
                <Input
                  type={"email"}
                  size={"large"}
                  onChange={handleEmailChange}
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  disabled={email === undefined}
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  Log in
              </Button>
              </Form.Item>

              <Form.Item>
                <Typography.Link href={"/register"}>
                  {"Don't have an account? Sign Up here"}
                </Typography.Link>
              </Form.Item>
            </Form>
          </>
        )}

      {requestStatus === "IN_PROGRESS" ? (
        <Typography.Title level={3}>Requesting Magic link ...</Typography.Title>
      ) : null}

      {requestStatus === "ERROR" ? (
        <Typography.Title level={3}>
          Could not request a magic link, please try one more time or contact us
          at <b>hello@mtdv.io</b>
        </Typography.Title>
      ) : null}
    </div>
  );
};
