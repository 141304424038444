import React from "react";
import { FocusList } from "@modules/focus/components/focus-list/FocusList";
import { OnboardingDomain } from "@modules/users/components/user-onboarding/OnboardingDomain";
import "./FocusScreen.scss";
import NetworkProgress from "@components/NetworkProgress";
import { Typography } from "antd";

const FocusScreen = () => {
  return (
    <div className="focus-screen">
      <Typography.Title level={3}>Your Focus</Typography.Title>
      <OnboardingDomain>
        {({ onboarding_complete, onSkipOnboarding }) =>
          <NetworkProgress
            entityType="Commitment"
            success={<FocusList />}
          />
        }
      </OnboardingDomain>
    </div>
  );
};

export default FocusScreen;
