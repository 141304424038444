import React from "react";
import Typography from "antd/lib/typography";
import { FocusCard } from "../focus-card/FocusCard";

import "./FocusList.scss";
import { PlaceholderFocusCard } from "../focus-card/PlaceholderFocusCard";
import { Row, Col } from "antd";
import { useWindowSize } from "@components/hooks/WindowsSizeHook";
import { useMst } from "@app/index";
import { observer } from "mobx-react-lite";

type FocusListProps = {}

const Component: React.FunctionComponent<FocusListProps> = () => {
  const [width] = useWindowSize();
  const { commitments } = useMst()

  const currentCommitments = commitments.focusCommitments
  const focusPlaceholderCount = commitments.focusPlaceholderCount

  const commitmentsCards = currentCommitments.map((c) => <FocusCard id={c.id} />);

  const placeholders = Array(focusPlaceholderCount)
    .fill(0)
    .map(() => {
      return <PlaceholderFocusCard />;
    });

  // right now we are rendering items in single row after 600px
  const justify = width < 600 ? "center" : undefined;

  return (
    <div className="focus-items-list">
      <Typography.Paragraph>
        Focus on <b>Collections</b> or <b>single posts</b> to{" "}
        <b>track your progress</b>. We will notify you <b>once every week</b>{" "}
        about the state of your <b>Focus</b>.
      </Typography.Paragraph>

      <Row justify={justify} align="top">
        {commitmentsCards.concat(placeholders).map((e) => (
          <Col>{e}</Col>
        ))}
      </Row>
    </div>
  );
};

export const FocusList = observer(Component);
