import React from "react";
import Typography from "antd/lib/typography";
import { CollectionCard } from "@modules/content/components/collection-card/CollectionCard";
import { Button, Col, Row } from "antd";

import "./LibraryOnboardingView.scss";
import { ICollection } from "@modules/content/stores/collections-store";

type LibraryOnboardingViewProps = {
  welcomeCollection: ICollection;
  onCommit: (collection: ICollection) => void;
  onSkipOnboarding: () => void;
};

const LibraryOnboardingView: React.FunctionComponent<LibraryOnboardingViewProps> = ({
  welcomeCollection,
  onCommit,
  onSkipOnboarding,
}) => {
  return (
    <div className="library-onboarding-view">
      <Row>
        <Col className="library-sample-collection">
          <CollectionCard collection={welcomeCollection} />
        </Col>
        <Col className="library-description">
          <Typography.Title level={3}>
            Let's focus on your first Welcome course.
          </Typography.Title>
          <Typography.Paragraph>
            Click on Focus slot to start tracking your progress through any
            Course or Quest.
          </Typography.Paragraph>

          <Button onClick={() => onSkipOnboarding()}>Skip onboarding</Button>
        </Col>
      </Row>
    </div>
  );
};

export default LibraryOnboardingView;
