import React, { ReactElement } from "react";
import { observer } from "mobx-react-lite";
import { ICollection } from "@modules/content/stores/collections-store";
import { useMst } from "@app/index";

interface OnboardingDomainProps {
  children(props: InjectedOnboardingProps): ReactElement;
}

export interface InjectedOnboardingProps {
  onboarding_complete: boolean;
  welcomeCollection: ICollection;
  onCommit: (collection: ICollection) => void;
  onSkipOnboarding: () => void;
}

const Component: React.FunctionComponent<OnboardingDomainProps> = ({
  children,
}) => {
  const { collections, settingsStore } = useMst()

  return children({
    onboarding_complete: settingsStore.settings?.dashboard_onboarding_status !== "NOT_STARTED",
    welcomeCollection: collections.byMtdvId['welcome'],
    onCommit: (collection) => {
      collections.commit(collection.id);
      settingsStore.update({
        dashboard_onboarding_status: "COMPLETED",
      });
    },
    onSkipOnboarding: () => {
      settingsStore.update({
        dashboard_onboarding_status: "SKIPPED",
      });
    },
  });
};

export const OnboardingDomain = observer(Component);
