import React from "react";
import { SettingsFormDomain } from "@modules/users/components/settings-form/SettingsFormDomain";
import SettingsFormView from "@modules/users/components/settings-form/SettingsFormView";
import "./SettingsScreen.scss";

const SettingsScreen = () => {
  return (
    <div className="settings-screen">
      <SettingsFormDomain>
        {(props) => <SettingsFormView {...props} />}
      </SettingsFormDomain>
    </div>
  );
};

export default SettingsScreen;
