import React from "react";
import LibraryListView from "@modules/categories/components/content-library-list/LibraryList";

import "./LibraryScreen.scss";
import { SearchDomain } from "@modules/search/components/SearchDomain";
import SearchView from "@modules/search/components/SearchView";
import { OnboardingDomain } from "@modules/users/components/user-onboarding/OnboardingDomain";
import LibraryOnboardingView from "@modules/categories/components/content-onboarding/LibraryOnboardingView";
import { useMst } from "@app/index";
import NetworkProgress from "@components/NetworkProgress";
import { observer } from "mobx-react-lite";

const LibraryScreen = () => {

  const {
    searchStore
  } = useMst();
  console.log('search-store', searchStore.searchPattern)

  return (<div className={"library-screen"}>
    <OnboardingDomain>
      {({
        onboarding_complete,
        welcomeCollection,
        onCommit,
        onSkipOnboarding,
      }) =>
        onboarding_complete ? (
          <NetworkProgress
            entityType="Commitment"
            success={realLibrary(!!searchStore.searchPattern)}
          />
        ) : (
            <LibraryOnboardingView
              welcomeCollection={welcomeCollection}
              onCommit={onCommit}
              onSkipOnboarding={onSkipOnboarding}
            />
          )
      }
    </OnboardingDomain>
  </div>)
};

const realLibrary = (isSearchActive: boolean) => (
  <>
    <SearchDomain>{(props) => <SearchView {...props} />}</SearchDomain>

    {/* we render search results in SearchView - would be good to change  */}
    {isSearchActive ? null : <LibraryListView />}
  </>
);

export default observer(LibraryScreen);
