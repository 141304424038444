import React from "react";
import { Typography } from "antd";
import moment from "moment";
import { ICommitment } from "@modules/focus/stores/commitments-store";
import { useLookup } from "@app/index";

interface StatusDateProps {
  id: ICommitment['id'];
}

const STATUS_TO_LABEL: { [status: string]: (c: ICommitment) => string } = {
  done: (c: ICommitment) => `Finished at ${moment(c.finished_date).fromNow()}`,
  committed: (c: ICommitment) =>
    `Committed at ${moment(c.committed_date).fromNow()}`,
  // TODO add half life reset 'half_life_reset': (c: Commitment) => `Half-Life-Reset at ${c.}`,
};

export const StatusDate: React.FunctionComponent<StatusDateProps> = ({
  id,
}) => {
  const commitment = useLookup('commitments', id)
  if (!commitment) return null

  return (
    <Typography.Text>
      {STATUS_TO_LABEL[commitment.status](commitment)}
    </Typography.Text>
  );
};
