import { types, getRoot, Instance } from "mobx-state-tree"
import { IRootStore } from "@app/rootStore";
import { createUserbaseUserOp, verifyEmailAddressExistsOp } from "../api/users-api";
import { baseNetworkOpsActions } from "@utils/store";

import firebase, { User } from "firebase";
import { runInAction } from "mobx";

const MAGIC_LINK_AUTH_CONFIG: firebase.auth.ActionCodeSettings = {
    url: process.env.REACT_APP_MAGIC_LINK_REDIRECT as string,
    handleCodeInApp: true,
    iOS: {
        bundleId: "<mtdv app ios package>", // implement deep links in rn app
    },
    android: {
        packageName: "<mtdv app android package>", // implement deep links in rn app
        installApp: true,
        minimumVersion: "12",
    },
    dynamicLinkDomain: process.env.REACT_APP_DYNAMIC_LINK_DOMAIN,
};

// TODO check if they are anyhow different
type MagicLinkRequestStatusType =
    | "IDLE"
    | "IN_PROGRESS"
    | "ERROR"
    | "SUCCESS"
    | "NOT_VALID_USER";
type SignupRequestStatusType = "IDLE" | "IN_PROGRESS" | "ERROR" | "SUCCESS";

const MagicLinkLoginError = types.frozen();
const SignupRequestError = types.frozen();

const FirebaseUser = types.model('FirebaseUser', {
    uid: types.string,
    email: types.maybeNull(types.string),
})

export interface IFirebaseUser extends Instance<typeof FirebaseUser> { }

const storeName = "AuthStore"
export const AuthStore = types
    .model(storeName, {
        currentUser: types.maybeNull(FirebaseUser),
        isInitComplete: types.optional(types.boolean, false),
        magicLinkLoginError: types.optional(types.maybe(MagicLinkLoginError), undefined),
        magiLinkRequestStatus: types.optional(types.union(
            types.literal("IDLE"),
            types.literal("IN_PROGRESS"),
            types.literal("ERROR"),
            types.literal("SUCCESS"),
            types.literal("NOT_VALID_USER")
        ), "IDLE"),
        signupRequestStatus: types.optional(types.union(
            types.literal("IDLE"),
            types.literal("IN_PROGRESS"),
            types.literal("ERROR"),
            types.literal("SUCCESS"),
        ), "IDLE"),
        signupRequestError: types.maybeNull(SignupRequestError)
    })
    .views(self => ({
        get root(): IRootStore {
            return getRoot(self)
        },
        get user(): IFirebaseUser | null {
            return self.currentUser
        },
        get isLoggedIn(): boolean {
            if (self.magicLinkLoginError !== undefined) {
                return false;
            }

            return self.currentUser != null;
        }
    }))
    .actions(self => ({
        ...baseNetworkOpsActions(self.root),
        ...{
            createAccount: function createAccount(email: string) {
                self.signupRequestStatus = "IN_PROGRESS";

                const op = createUserbaseUserOp(email, false);
                self.root.networkSystem.execute(op)

                this.trackSuccessOp(op.id, (sucessOp) => {
                    console.info('Account created')

                    firebase
                        .auth()
                        .sendSignInLinkToEmail(email, MAGIC_LINK_AUTH_CONFIG)
                        .then(() => {
                            this.setSignupRequestStatus("SUCCESS");
                            window.localStorage.setItem("emailForSignIn", email);
                            self.root.notificationsSystem.success("Requested login link!");
                        })
                        .catch((err) => {
                            console.error("Can't sendMagicSignInLink", err);
                            this.setSignupRequestStatus("ERROR");
                            self.root.notificationsSystem.error("Ops, unable to request login link");
                            return err;
                        });

                }, (errorOp) => {
                    console.error("Can't create user account", errorOp.error);
                    this.setSignupRequestStatus("ERROR");
                    self.root.notificationsSystem.error(
                        "Ops, unable to create user account"
                    );
                })
            },
            completeLoginWithMagicLink(
                email: string,
                locationHref: string
            ): Promise<void> {
                return firebase
                    .auth()
                    .signInWithEmailLink(email, locationHref)
                    .then((res) => {
                        this.setCurrentUser(res.user);
                        window.localStorage.removeItem("emailForSignIn");
                        self.root.notificationsSystem.success(
                            "Successfully logged in"
                        );
                    })
                    .catch((error) => {
                        window.localStorage.removeItem("emailForSignIn");
                        self.root.notificationsSystem.success(
                            "Failed to login"
                        );
                    });
            },
            sendMagicSignInLink(email: string) {
                self.magiLinkRequestStatus = "IN_PROGRESS";

                const op = verifyEmailAddressExistsOp(email)
                self.root.networkSystem.execute(op)

                this.trackSuccessOp(op.id, (sucessOp) => {
                    const verificationResult = sucessOp.data
                    if (verificationResult) {
                        return firebase
                            .auth()
                            .sendSignInLinkToEmail(email, MAGIC_LINK_AUTH_CONFIG)
                            .catch((err) => {
                                console.error("Can't sendMagicSignInLink", err);
                                self.root.notificationsSystem.error(
                                    "Ops, unable to request login link"
                                );
                            })
                            .then(() => {
                                this.setMagicLinkStatus("SUCCESS");
                                window.localStorage.setItem("emailForSignIn", email);
                                self.root.notificationsSystem.success(
                                    "Requested login link!"
                                );
                            })

                    } else {
                        throw new Error("Failed to verify user");
                    }
                }, (errorOp) => {
                    const verificationError = errorOp.error;
                    this.setMagicLinkStatus("NOT_VALID_USER");
                    self.root.notificationsSystem.info(
                        "Not found user with such email, please sign-up"
                    );
                    console.error("Failed to verify user", verificationError);
                    throw verificationError;
                })
            },
            trackAuthStateChanges() {
                console.debug("Requested auth state change");

                firebase.auth().onAuthStateChanged((user) => {
                    runInAction(() => {
                        if (user) {
                            this.setCurrentUser(user)
                        } else {
                            this.setCurrentUser(null)
                        }
                        this.setInitComplete(true);
                    })
                });

                firebase.auth().onIdTokenChanged((user) => {
                    runInAction(() => {
                        if (user) {
                            console.debug("Found logged in user", user);
                            this.setCurrentUser(user)
                        } else {
                            console.debug("No active user found");
                            this.setCurrentUser(null)
                        }
                        this.setInitComplete(true);
                    })
                });

                this.setCurrentUser(firebase.auth().currentUser)
            },
            logout() {
                firebase
                    .auth()
                    .signOut()
                    .then(
                        () => {
                            this.setCurrentUser(null);
                            window.location.reload();
                        },
                        function (error) {
                            console.log("Error unable to logout", error);
                            alert("Could not sign out!");
                        }
                    );
            },
            setCurrentUser(user: User | null) {
                if (user) {
                    self.currentUser = {
                        uid: user.uid,
                        email: user.email
                    }
                }
            },
            setMagicLinkStatus(status: MagicLinkRequestStatusType) {
                self.magiLinkRequestStatus = status
            },
            setMagicLinkLoginError(error: Error) {
                self.magicLinkLoginError = error
            },
            setSignupRequestError(error: Error) {
                self.signupRequestError = error
            },
            setSignupRequestStatus(status: SignupRequestStatusType) {
                self.signupRequestStatus = status
            },
            setInitComplete(isInitComplete: boolean) {
                self.isInitComplete = isInitComplete
            }
        }
    }))
