import React from "react";
import { Form, Checkbox, Button } from "antd";

import "./SettingsFormView.scss";
import Typography from "antd/lib/typography";
import { InjectedSettingsFormProps } from "./SettingsFormDomain";
import { IUserSettings } from "@modules/users/stores/settings-store";

const SettingsFormView: React.FunctionComponent<InjectedSettingsFormProps> = ({
  settings,
  onUpdateSettings,
}) => {
  const [form] = Form.useForm();

  const onFinish = (values: Partial<IUserSettings>) => {
    onUpdateSettings({
      global_notifications_enabled: values.global_notifications_enabled,
    });
  };

  if (settings === undefined) {
    return (
      <div className="settings-form">
        <Typography.Paragraph>Settings are loading ...</Typography.Paragraph>
      </div>
    );
  }

  form.setFieldsValue({
    global_notifications_enabled: settings.global_notifications_enabled,
  });

  return (
    <div className="settings-form">
      <Typography.Title level={3}>Edit your profile settings</Typography.Title>
      <Form form={form} name="settings" onFinish={onFinish}>
        <Form.Item name="global_notifications_enabled" valuePropName="checked">
          <Checkbox>Enabled email notifications</Checkbox>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SettingsFormView;
