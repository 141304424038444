import React, { useEffect } from "react";
import { Empty, PageHeader } from "antd";
import { Stack } from "@components/structure/Stack";

import "./PostDetails.scss";
import { PostComponentSelector } from "./PostComponentSelector";
import { StatusTag } from "@components/common/status-tag/StatusTag";
import { StatusActionButton } from "@components/common/status-button/StatusActionButton";
import { observer } from "mobx-react-lite";
import { useMst } from "@app/index";

type PostDetailsProps = {
  postMtdvId: string
}

const emptyState = (
  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}>Not found such Post</Empty>
);

const Component: React.FunctionComponent<PostDetailsProps> = ({
  postMtdvId
}) => {
  const { posts, commitments } = useMst()
  // we need to scroll to the top of the page since user could nagivate down searching stuff in library
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const post = posts.byMtdvId[postMtdvId]
  if (post === undefined) {
    return emptyState;
  }

  // it makes sense to search for commitment if there a post found
  const commitment = commitments.byPost[post.id]
  const hasFocusSlots = commitments.hasFocusSlots

  let subTitle = null;
  if (commitment !== undefined) {
    subTitle = <StatusTag status={commitment.status} />;
  }

  return (
    <div className="post-details">
      <Stack>
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title={`${post.title}`}
          subTitle={subTitle}
          extra={
            <StatusActionButton
              commitment={commitment}
              hasFocusSlots={hasFocusSlots}
              onCommit={() => posts.commit(post.id)}
              onFinish={() => posts.finish(post.id)}
              onReset={() => posts.reset(post.id)}
            />
          }
        />
        <div className="page-component">
          <PostComponentSelector post={post} />
        </div>
      </Stack>
    </div>
  );
};

export const PostDetails = observer(Component);
