import React from "react";
import Typography from "antd/lib/typography";
import { MtdvMarsonyGrid } from "@components/structure/MtdvMarsonyGrid";
import { CollectionCard } from "@modules/content/components/collection-card/CollectionCard";

import "./LibraryList.scss";
import { useWindowSize } from "@components/hooks/WindowsSizeHook";
import { Alert, Row } from "antd";
import { useMst } from "@app/index";
import { observer } from "mobx-react-lite";
import { ICategory } from "@modules/categories/stores/categories-store";
import { useLocation } from "react-router";

const LibraryListView: React.FunctionComponent<{}> = () => {
  const location = useLocation();
  const { commitments, categories } = useMst()

  const [width] = useWindowSize();

  // right now we are rendering items in single row after 600px
  const marginLeft = width < 600 ? 20 : -20;

  // we are interested in categories that have content
  const libraryCategories = categories
    .categoriesByPage(location.pathname)
    .filter(category => category.items.length > 0)

  const hasFocusSlots = commitments.hasFocusSlots

  const collectionCards = (category: ICategory) => category.items$.map((collection) => (
    <CollectionCard collection={collection} />
  ))

  return (
    <div className="library-items-list">
      {libraryCategories.map((category) => (
        <div>
          {hasFocusSlots ? undefined : (
            <Alert
              style={{ marginBottom: 15 }}
              message="You have used all Focus slots. Before focusing on something new - resolve or undo existing items in Focus."
              type="warning"
            />
          )}

          <Typography.Title style={{ marginLeft: marginLeft }} level={3}>
            {category.title}
          </Typography.Title>

          {width < 600 ?
            <Row justify="center">
              {collectionCards(category)}
            </Row>
            :
            <MtdvMarsonyGrid>
              {collectionCards(category)}
            </MtdvMarsonyGrid>
          }
        </div>
      ))}
    </div>
  );
};

export default observer(LibraryListView);
