import { autorun } from "mobx";
import { types, Instance, unprotect } from "mobx-state-tree"
import { PostStore } from "@modules/content/stores/posts-store";
import { CollectionStore } from "@modules/content/stores/collections-store";
import { CommitmentStore } from "@modules/focus/stores/commitments-store";
import { categoriesData, CategoryStore } from "@modules/categories/stores/categories-store";
import { NetworkOperationStore } from "@systems/network/network-system";
import { NotificationsStore } from "@systems/notifications/notifications-system";
import { AuthStore } from "@modules/users/stores/auth-store";
import { SettingsStore } from "@modules/users/stores/settings-store";
import { SearchStore } from "@systems/search/search-system";

const RootStore = types.model({
  posts: types.optional(PostStore, {}),
  collections: types.optional(CollectionStore, {}),
  categories: types.optional(CategoryStore, {}),
  commitments: types.optional(CommitmentStore, {}),
  networkSystem: types.optional(NetworkOperationStore, {}),
  notificationsSystem: types.optional(NotificationsStore, {}),
  authStore: types.optional(AuthStore, {}),
  settingsStore: types.optional(SettingsStore, {}),
  searchStore: types.optional(SearchStore, {}),
}).actions(self => ({
  afterCreate() {
    autorun(() => {

      // start fetching only when currentUser is available
      if (self.authStore.currentUser) {
        self.commitments.fetch()
        self.settingsStore.fetch()
        self.posts.fetch()
        self.collections.fetch()
      }
    })
  }
}));

export const createStore = () => {
  return RootStore.create({
    categories: categoriesData
  })
}

// see https://github.com/mobxjs/mobx-state-tree/issues/1406#issuecomment-547092491
export interface IRootStore extends Instance<typeof RootStore> { }