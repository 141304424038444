import React from "react";
import { useMst } from "@app/index";
import { useWindowSize } from "../hooks/WindowsSizeHook";
import HorisonalNavigation from "./HorisontalNavigation";
import DrawerNavigationView from "./DrawerNavigation";

interface NavigationViewProps { }

const NavigationView: React.FunctionComponent<NavigationViewProps> = () => {
  const { authStore } = useMst();
  const [width] = useWindowSize();

  if (width > 820) {
    return (
      <HorisonalNavigation
        user={authStore.user}
        onLogout={() => authStore.logout()}
      />
    );
  } else {
    return (
      <DrawerNavigationView
        user={authStore.user}
        onLogout={() => authStore.logout()}
      />
    );
  }
};

export default NavigationView;
