import React from "react";
import { IPost } from "@modules/content/stores/posts-store";

interface QuestPostViewProps {
  post: IPost;
}

export const QuestPostView: React.FunctionComponent<QuestPostViewProps> = ({
  post,
}) => {
  return <div>Quest</div>;
};
