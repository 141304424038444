import React from "react";
import { List } from "antd";
import {
  CheckCircleTwoTone,
  ThunderboltTwoTone,
} from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";

import "./CollectionPosts.scss";
import { EmptyCircle } from "@components/common/icons/EmptyCircle";
import { useMst } from "@app/index"
import { IPost } from "@modules/content/stores/posts-store";
import { ICommitment } from "@modules/focus/stores/commitments-store";
import { observer } from "mobx-react-lite";
import { LiveRef } from "@utils/liveReference";
import { ICollection } from "@modules/content/stores/collections-store";
import { PostsDataSource } from "../PostsDataSource";

interface CollectionPostsProps {
  collectionId: ICollection['id'] | LiveRef<ICollection>
}

const Component: React.FunctionComponent<CollectionPostsProps> = ({ collectionId }) => {
  const { commitments } = useMst()
  const history = useHistory();

  const handleIconClick = (post: IPost) => () => history.push(`/library/posts/${post?.mtdv_id}`);

  return (
    <PostsDataSource collectionId={collectionId}>
      {
        ({ posts }) =>
          <List
            className="collection-posts"
            itemLayout="horizontal"
            dataSource={posts}
            renderItem={(post) => {
              const commitment = commitments.byPost[post.id];

              return (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <span style={{ cursor: "pointer" }}>
                        {renderProgressIcon(
                          commitment,
                          handleIconClick(post)
                        )}
                      </span>
                    }
                    title={
                      <Link to={`/library/posts/${post?.mtdv_id}`}>
                        {post?.title}
                      </Link>
                    }
                  />
                </List.Item>
              );
            }}
          />
      }
    </PostsDataSource >
  );
};

function renderProgressIcon(
  commitment: ICommitment | undefined,
  handleIconClick: () => void
) {
  const initialIcon = <EmptyCircle onClick={handleIconClick} />;
  const finishedIcon = (
    <CheckCircleTwoTone twoToneColor="#52c41a" onClick={handleIconClick} />
  );
  const inProgressIcon = (
    <ThunderboltTwoTone twoToneColor="#ffa940" onClick={handleIconClick} />
  );

  if (commitment !== undefined) {
    if (commitment.status === "done") {
      return finishedIcon;
    } else if (commitment.status === "committed") {
      return inProgressIcon;
    }
  }

  return initialIcon;
}

export const CollectionPosts = observer(Component);