import React, { ReactElement, useEffect } from "react";
import { observer } from "mobx-react-lite";

import { useMst } from "@app/index";
import { IUserSettings } from "@modules/users/stores/settings-store";

interface SettingsFormDomainProps {
  children(props: InjectedSettingsFormProps): ReactElement;
}

export interface InjectedSettingsFormProps {
  settings: IUserSettings | undefined;
  onUpdateSettings: (settings: Partial<IUserSettings>) => void;
}

const Component: React.FunctionComponent<SettingsFormDomainProps> = ({
  children,
}) => {
  const { settingsStore } = useMst();

  useEffect(() => {
    settingsStore.fetch();
  }, []);

  return children({
    settings: settingsStore.settings,
    onUpdateSettings: (settings) => settingsStore.update(settings),
  });
};

export const SettingsFormDomain = observer(Component);
