import React from "react";

import { useParams } from "react-router";
import "./PostDetailsScreen.scss";
import { PostDetails } from "@modules/content/components/post-details/PostDetails";

type PostDetailsScreenRouteParams = {
  id: string;
};

const PostDetailsScreen = () => {
  const { id } = useParams<PostDetailsScreenRouteParams>();

  return (
    <div className="post-details-screen">
      <PostDetails postMtdvId={id} />
    </div>
  );
};

export default PostDetailsScreen;
