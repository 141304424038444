import { createNetworkOperation, INetworkOperation } from "@systems/network/network-system";
import { IUserSettings, UserSettings } from "../stores/settings-store";

const MTDV_USERBASE_URL = process.env.REACT_APP_MTDV_USERBASE_URL;

export function createUserbaseUserOp(
    email: string,
    newsletterConsent: boolean = false
): INetworkOperation {
    return createNetworkOperation({
        operation: 'create',
        entityType: UserSettings.name,
        entityId: '*',
        url: `${MTDV_USERBASE_URL}/api/public/users`,
        method: 'POST',
        auth: false,
        body: {
            email: email,
            newsletter_consent: newsletterConsent,
        }
    })
}

export function verifyEmailAddressExistsOp(
    email: string
): INetworkOperation {
    return createNetworkOperation({
        operation: 'getOne',
        entityType: 'EmailAddressVerification',
        entityId: '*',
        url: `${MTDV_USERBASE_URL}/api/public/email-verification`,
        method: 'POST',
        auth: false,
        body: {
            email: email,
        }
    })
}

export function fetchUserbaseUserOp(): INetworkOperation {
    return createNetworkOperation({
        operation: 'getAll',
        entityType: UserSettings.name,
        entityId: '*',
        url: `${MTDV_USERBASE_URL}/api/public/users/me`,
        method: 'GET',
        auth: true,
        body: undefined
    })
}

export function updateSettings(
    settings: Partial<IUserSettings>
): INetworkOperation {
    return createNetworkOperation({
        operation: 'update',
        entityType: UserSettings.name,
        entityId: settings.id || "*",
        url: `${MTDV_USERBASE_URL}/api/private/users/me/settings`,
        method: 'PATCH',
        auth: true,
        body: settings
    })
}
