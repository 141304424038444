import { types, Instance, getRoot } from "mobx-state-tree"
import { IRootStore } from '@app/rootStore'
import { indexEntityByKey } from "@utils/index"
import { LiveRef } from "@utils/liveReference"

import { fetchPostsOp } from "../api/content-api"
import { modifyCommitmentOp } from "@modules/focus/api/focus-api";
import { baseStoreActions } from "@utils/store"
import { ICommitment } from "@modules/focus/stores/commitments-store"

interface StrapiImageFormat {
    ext: string;
    hash: string;
    height: number;
    mime: string;
    url: string;
    width: number;
}

interface StrapiImageFormats {
    large: StrapiImageFormat;
    medium: StrapiImageFormat;
    small: StrapiImageFormat;
    thumbnail: StrapiImageFormat;
}

export interface StrapiImage {
    id: number;
    alternativeText: string;
    caption: string;
    height: number;
    width: number;
    url: string;
    mime: string;
    formats: StrapiImageFormats;
}

export const StrapiImage = types.frozen<StrapiImage>()

export const Post = types.model("Post", {
    _type: types.optional(types.literal('post'), 'post'),
    id: types.identifierNumber,
    mtdv_id: types.string,
    title: types.string,
    content_type: types.string,
    preview_description: types.maybeNull(types.string),
    preview_image: types.maybeNull(types.string),
    content: types.maybeNull(types.string),
    enabled: types.boolean,
    external_link: types.maybeNull(types.string),
    access_level: types.string,
    created_at: types.string,
    updated_at: types.string,
})

export interface IPost extends Instance<typeof Post> { }

const storeName = "PostStore"
export const PostStore = types
    .model(storeName, {
        _byId: types.map(Post)
    })
    .views(self => ({
        get root(): IRootStore {
            return getRoot(self)
        },
        get byMtdvId() {
            return indexEntityByKey('mtdv_id', Array.from(self._byId.values()))
        },
        byId(id: number) {
            return self._byId.get(id.toString())
        },
        find(id: number | LiveRef<IPost>) {
            return typeof id === 'number'
                ? this.byId(id)
                : id.ref
        }
    }))
    .actions((self) => {
        return {
            ...baseStoreActions<typeof Post, IPost>(storeName, self.root, self._byId),
            ...{
                fetch() {
                    const op = fetchPostsOp();
                    self.root.networkSystem.execute(op)

                    this.trackSuccessOp(op.id, (sucessOp) => {
                        this.setAll(sucessOp.data)
                        self.root.searchStore.setEntities("Post", sucessOp.data)
                    })
                },
                commit: function (id: number) {
                    const post = self._byId.get(id.toString())
                    if (post) {
                        const op = modifyCommitmentOp(post.id, "posts", "commit");
                        self.root.networkSystem.execute(op)

                        this.trackSuccessOp(op.id, (sucessOp) => {
                            this.updateCommitment(sucessOp.data)
                        })

                        // // mst can freak out when schema is not matching
                        // const commitment = self.root.commitments.byCollection[collection.id];
                        // self.root.commitments.put(cast({ ...commitment, status: updatedCommitment.status }))
                    }
                },
                reset: function (id: number) {
                    const post = self._byId.get(id.toString())
                    if (post) {
                        const op = modifyCommitmentOp(post.id, "posts", "reset");
                        self.root.networkSystem.execute(op)

                        this.trackSuccessOp(op.id, (sucessOp) => {
                            this.updateCommitment(sucessOp.data)
                        })

                        // // mst can freak out when schema is not matching
                        // const commitment = self.root.commitments.byCollection[collection.id];
                        // self.root.commitments.put(cast({ ...commitment, status: updatedCommitment.status }))
                    }
                },
                finish: function (id: number) {
                    const post = self._byId.get(id.toString())
                    if (post) {
                        const op = modifyCommitmentOp(post.id, "posts", "finish");
                        self.root.networkSystem.execute(op)

                        this.trackSuccessOp(op.id, (sucessOp) => {
                            this.updateCommitment(sucessOp.data)
                        })

                        // // mst can freak out when schema is not matching
                        // const commitment = self.root.commitments.byCollection[collection.id];
                        // self.root.commitments.put(cast({ ...commitment, status: updatedCommitment.status }))
                    }
                },
                updateCommitment(updatedCommitment: ICommitment) {
                    self.root.commitments.set(updatedCommitment)
                }
            }
        }
    });

// see https://github.com/mobxjs/mobx-state-tree/issues/1406#issuecomment-547092491
export interface IPostStore extends Instance<typeof Post> { }

