import React, { ReactElement } from "react";
import { observer } from "mobx-react-lite";
import { useMst } from "@app/index";
import { IPost } from "@modules/content/stores/posts-store";
import { ICollection } from "@modules/content/stores/collections-store";
import { LiveRef, resolve } from "@utils/liveReference";

type ByCollectionProps = {
  collectionId: number | LiveRef<ICollection>
}

type ByIdProps = {
  id: number | LiveRef<IPost>
}

export type PostsResult = {
  posts: IPost[]
}

export type PostResult = {
  post: IPost | LiveRef<IPost> | undefined
}

type XOR<T, U> = (T | U) extends object
  ? (Omit<T, keyof U> & U) | (Omit<U, keyof T> & T)
  : T | U

type ChildRenderer = {
  children: (props: PostResult & PostsResult) => ReactElement
}

type PostDataSourceProps = ByCollectionProps | ByIdProps

const Component: React.FunctionComponent<PostDataSourceProps & ChildRenderer> = props => {
  const { posts, collections } = useMst()
  let collection: ICollection | undefined = undefined
  let allPosts: IPost | undefined | IPost[] = undefined

  if ('id' in props) {
    allPosts = posts.find(props.id)
  }
  if ('collectionId' in props) {
    collection = collections.find(props.collectionId)
    if (collection) {
      allPosts = resolve(collection.posts)
    } else {
      return null // no target collection - render nothing
    }
  }

  const params = Array.isArray(allPosts)
    ? { posts: allPosts, post: undefined }
    // posts guaranteed to be present, but empty
    : { post: allPosts, posts: [] }
  return props.children ? props.children(params) : null
};

export const PostsDataSource = observer(Component)
