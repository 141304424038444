
import { types, getRoot, Instance } from "mobx-state-tree"
import { IRootStore } from "@app/rootStore";
import { fetchUserbaseUserOp, updateSettings } from "../api/users-api";
import { baseNetworkOpsActions } from "@utils/store";

export const UserSettings = types.model("UserSettings", {
    id: types.maybeNull(types.number),
    display_name: types.maybe(types.string),
    slack_nickname: types.maybe(types.string),
    preferred_notification_channel: types.maybe(types.string),
    dashboard_onboarding_status: types.maybe(types.string),
    global_notifications_enabled: types.maybe(types.boolean),
    newsletter_consent: types.maybe(types.boolean),
    avatar_url: types.maybe(types.string),
    location: types.maybe(types.string),
    timezone: types.maybe(types.string),
})
    .views(self => ({
        get root(): IRootStore {
            return getRoot(self)
        }
    }))

export interface IUserSettings extends Instance<typeof UserSettings> { };

const storeName = "SettingsStore"
export const SettingsStore = types
    .model(storeName, {
        settings: types.optional(UserSettings, {}),
    })
    .views(self => ({
        get root(): IRootStore {
            return getRoot(self)
        }
    }))
    .actions(self => ({
        ...baseNetworkOpsActions(self.root),
        fetch: function () {
            const op = fetchUserbaseUserOp()
            this.trackSuccessOp(op.id, (successOp) => {
                this.setSettings(op.data.data)
            })
        },
        update: function (settings: Partial<IUserSettings>) {
            const op = updateSettings(settings)
            this.trackSuccessOp(op.id, (successOp) => {
                this.setSettings(op.data.data)
            })
        },
        setSettings(settings: IUserSettings) {
            self.settings = settings
        }
    }));