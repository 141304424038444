import React from "react";
import classnames from "classnames";
import { Card } from "antd";
import "./CollectionCard.scss";
import { Link } from "react-router-dom";
import { StatusButton } from "@components/common/status-button/StausButton";
import { StatusTag } from "@components/common/status-tag/StatusTag";
import { ICollection } from "@modules/content/stores/collections-store";
import { useMst } from "@app/index"
import { CollectionPosts } from "./CollectionPosts";
import { observer } from "mobx-react-lite";

type CollectionCardProps = {
  collection: ICollection;
};

const Component: React.FunctionComponent<CollectionCardProps> = ({
  collection,
}) => {
  const { collections, commitments } = useMst()
  const cls = classnames("collection-card");

  if (!collection) {
    return null;
  }

  const commitment = commitments.byCollection[collection.id]
  const hasFocusSlots = commitments.hasFocusSlots

  // we want users to commit to more items
  let statusIcon = undefined;
  if ((commitment === undefined || commitment.status === "reset") && hasFocusSlots) {
    statusIcon = (
      <StatusButton
        title={"Focus"}
        cls="commit"
        onClick={() => collections.commit(collection.id)}
      />
    );
  }

  return (
    <Card
      className={cls}
      title={
        <>
          <Link to={`/library/collections/${collection.mtdv_id}`}>
            {collection.title}
          </Link>
          {commitment !== undefined ? (
            <span style={{ float: "right" }}>
              <StatusTag status={commitment.status} />
            </span>
          ) : undefined}
        </>
      }
      actions={[statusIcon]}
      headStyle={{ backgroundColor: collection.preview_colour }}
    >
      <CollectionPosts collectionId={collection.id} />
    </Card>
  );
};

export const CollectionCard = observer(Component)