import React, { ReactElement } from "react";
import { Tag } from "antd";
import { StatusButton } from "./StausButton";
import { ICommitment } from "@modules/focus/stores/commitments-store";

interface StatusActionButtonProps {
  commitment: ICommitment | undefined;
  hasFocusSlots: boolean;
  onCommit: () => void;
  onFinish: () => void;
  onReset: () => void;
}

export const StatusActionButton: React.FunctionComponent<StatusActionButtonProps> = ({
  commitment,
  hasFocusSlots,
  onCommit,
  onFinish,
  onReset,
}) => {
  let commitButton: ReactElement = (
    <StatusButton title={"Focus"} cls="commit" onClick={onCommit} />
  );

  if (!hasFocusSlots) {
    commitButton = <Tag>No Focus slots available</Tag>;
  }

  const resetButton = (
    <StatusButton title={"Undo"} cls="reset" onClick={onReset} />
  );

  const finishButton = (
    <StatusButton title={"Mark as Done"} cls="finish" onClick={onFinish} />
  );

  const STATUS_TO_BUTTON: {
    [status: string]: ReactElement | ReactElement[];
  } = {
    committed: [finishButton, resetButton],
    done: resetButton,
  };

  let component;
  if (commitment === undefined) {
    component = commitButton;
  } else {
    component = STATUS_TO_BUTTON[commitment.status] || commitButton;
  }

  return <span className="status-actions-container">{component}</span>;
};
