import { types, Instance, getRoot } from "mobx-state-tree"
import { IRootStore } from "@app/rootStore";
import { indexEntitesByKey } from "@utils/index";
import { ICollection } from "@modules/content/stores/collections-store";

import collectionCategories from "./collections-categories.json";

export const Category = types.model('Category', {
    name: types.identifier,
    page: types.string,
    title: types.string,
    items: types.array(types.string)
}).views(self => ({
    get root(): IRootStore {
        return getRoot(self)
    },
    get items$(): ICollection[] {
        return self.items.map(mtdv_id => this.root.collections.byMtdvId[mtdv_id])
    }
}))

export interface ICategory extends Instance<typeof Category> { };

export const CategoryStore = types.model('CategoryStore', {
    byName: types.map(Category)
}).views(self => {
    return {
        get byPage() {
            return indexEntitesByKey('page', Array.from(self.byName.values()))
        },
        categoriesByPage: function categoriesByPage(page: string): ICategory[] {
            return this.byPage[page] || []
        }
    }
})

export interface CategoriesPerPage {
    [page: string]: RawCategorizedCollections;
}

export interface RawCategorizedCollections {
    [category_id: string]: RawCollectionCategory;
}

interface RawCollectionCategory {
    title: string;
    items: string[];
}

function prepareCategoriesRawData(collectionCategories: CategoriesPerPage) {
    let val = {}
    Object.keys(collectionCategories).map(page => {
        const collectionsIdxPerPage = collectionCategories[page]

        const categoriesWithPages = Object.keys(collectionsIdxPerPage).reduce((acc, collectionName) => {
            const collection = collectionsIdxPerPage[collectionName]
            acc[collectionName] = { ...collection, page: page, name: collectionName }
            return acc;
        }, {} as { [collectionName: string]: {} })

        val = { ...categoriesWithPages, ...val }

        return val;
    })
    return val;
}

export const categoriesData = {
    byName: prepareCategoriesRawData(collectionCategories as CategoriesPerPage)
}