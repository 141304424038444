import React from "react";
import { LoginFormView } from "./LoginFormView";
import { observer } from "mobx-react-lite";
import { useMst } from "@app/index";

const LoginFormDomain: React.FunctionComponent = (props) => {
  const { authStore } = useMst();
  return (
    <LoginFormView
      onSubmit={(email) => authStore.sendMagicSignInLink(email)}
      requestStatus={authStore.magiLinkRequestStatus}
    />
  );
};

export default observer(LoginFormDomain);
