import React from "react";
import { Typography } from "antd";
import { Link } from "react-router-dom";

import "./CurrentCollectionPostView.scss";
import { IPost } from "@modules/content/stores/posts-store";

interface CurrentCollectionPostViewProps {
  post: IPost;
}

export const CurrentCollectionPostView: React.FunctionComponent<CurrentCollectionPostViewProps> = ({
  post,
}) => {
  return (
    <div className="current-collection-post">
      <Typography.Paragraph className="current-collection-post__title">
        Current focus post
      </Typography.Paragraph>
      <Link to={`/library/posts/${post.mtdv_id}`}>{post.title}</Link>
    </div>
  );
};
