import React from "react";
import ReactMarkdown from "react-markdown";
import { IPost } from "@modules/content/stores/posts-store";
import "./InternalArticlePostView.scss";

interface InternalArticlePostViewProps {
  post: IPost;
}

export const InternalArticlePostView: React.FunctionComponent<InternalArticlePostViewProps> = ({
  post,
}) => {
  return (
    <div className="internal-article">
      {post.content !== undefined ? (
        <ReactMarkdown source={post.content!} />
      ) : null}
    </div>
  );
};
