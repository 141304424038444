import React from "react";
import SignupFormDomain from "./SignupFormDomain";

import "./SignupScreen.scss";

export default function SignInSide() {
  return (
    <div className="register-screen">
      <SignupFormDomain />
    </div>
  );
}
