import React from "react";
import { IPost } from "@modules/content/stores/posts-store";

import { PreviewLink } from "@components/common/preview-link/PreviewLink";

import "./ExternalLinkPostView.scss";

export interface ExternalLinkPostViewProps {
  post: IPost;
}

export const ExternalLinkPostView: React.FunctionComponent<ExternalLinkPostViewProps> = ({
  post,
}) => {
  return (
    <div className="external-link-post-view">
      <PreviewLink link={post.external_link!} />
    </div>
  );
};
