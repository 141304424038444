import React, { ReactElement } from "react";
import { observer } from "mobx-react-lite";

import { useMst } from "@app/index";
import { SearchableEntity } from "@systems/search/search-system";

interface SearchDomainProps {
  children(props: InjectedSearchProps): ReactElement;
}

export interface InjectedSearchProps {
  onSearch: (pattern: string) => SearchableEntity[];
  onClearSearch: () => void;
  searchResults: SearchableEntity[];
  searchPattern: string | null;
}

const Component: React.FunctionComponent<SearchDomainProps> = ({
  children,
}) => {
  const { searchStore } = useMst();

  return children({
    onSearch: (pattern: string) => searchStore.search(pattern),
    onClearSearch: () => searchStore.clear(),
    searchResults: searchStore.searchResults,
    searchPattern: searchStore.searchPattern,
  });
};

export const SearchDomain = observer(Component);
