import React, { createContext, useContext } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import firebase from "firebase";
import { createStore, IRootStore } from './rootStore';
import firebaseConfig from "@app/firebase-config";

firebase.initializeApp(firebaseConfig);

const rootStore = createStore();
rootStore.authStore.trackAuthStateChanges();

(window as any).rootStore = rootStore;
const RootStoreContext = createContext<null | IRootStore>(null);

export const MstRootStoreProvider = RootStoreContext.Provider;
export function useMst() {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error("Store cannot be null, please add a context provider");
  }
  return store;
}

export function useLookup<
  Store extends keyof IRootStore,
  T extends ReturnType<IRootStore[Store]['byId']>
>
  (store: Store, id: string | number): T {
  return useMst()[store].byId(id)
}

const app = (
  <MstRootStoreProvider value={rootStore}>
    <React.StrictMode>
      <Router>
        <App />
      </Router>
    </React.StrictMode>
  </MstRootStoreProvider>
);

// apparently login link should be first to try with firebase
if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
  let email: string | null = window.localStorage.getItem("emailForSignIn");
  if (!email) {
    email = window.prompt("Please provide your email for confirmation");
  }

  firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(() =>
      rootStore.authStore.completeLoginWithMagicLink(
        email as string,
        window.location.href
      )
    )
    .then(() => {
      window.location.href = "/";
    })
    .catch((e) => {
      console.log("firebase auth", e);
    });
}

ReactDOM.render(app, document.getElementsByTagName("body")[0]);
serviceWorker.unregister();
