import React from "react";
import { Col, Row } from "antd";
import "./HorisontalNavigation.scss";
import { Link } from "react-router-dom";
import { MainMenuView } from "./MainMenuView";
import { SettingsMenuView } from "./SettingsMenuView";
import { IFirebaseUser } from "@modules/users/stores/auth-store";

interface HorisonalNavigationViewProps {
  user: IFirebaseUser | null;
  onLogout: () => void;
}

const HorisonalNavigation: React.FunctionComponent<HorisonalNavigationViewProps> = ({
  user,
  onLogout,
}) => {
  return (
    <header className={"horisontal-navigation"}>
      <Row>
        <Col flex="100px">
          <h1>
            <Link className="logo" to="/">
              <img
                alt="logo"
                src="https://thumb.tildacdn.com/tild6165-3464-4834-b235-666239643538/-/resize/140x/-/format/webp/sx198zeBbco2DbnswuOz.png"
              />
              Dashboard
            </Link>
          </h1>
        </Col>
        <Col flex="auto">
          <MainMenuView orientation="horizontal" />
        </Col>

        <Col flex="200px">
          <SettingsMenuView user={user} onLogout={onLogout} />
        </Col>
      </Row>
    </header>
  );
};

export default HorisonalNavigation;
