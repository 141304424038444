import React from "react";

import { InternalArticlePostView } from "./InternalArticlePostView";
import { ExternalLinkPostView } from "./ExternalLinkPostView";
import { QuestPostView } from "./QuestPostView";
import { IPost } from "@modules/content/stores/posts-store";

interface PostComponentSelectorProps {
  post: IPost;
}

function selectComponent(post: IPost) {
  if (
    post.content_type === "internal_article" ||
    post.content_type === "quest"
  ) {
    return <InternalArticlePostView post={post} />;
  } else if (post.content_type === "external_link") {
    return <ExternalLinkPostView post={post} />;
  } else if (post.content_type === "external_youtube_video") {
    return <ExternalLinkPostView post={post} />;
  } else if (post.content_type === "quest") {
    return <QuestPostView post={post} />;
  } else {
    return null;
  }
}

export const PostComponentSelector: React.FunctionComponent<PostComponentSelectorProps> = ({
  post,
}) => {
  return selectComponent(post);
};
