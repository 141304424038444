import React, { useState } from "react";
import { Col, Row, Drawer } from "antd";
import { HamburgerIcon } from "../common/icons/HamburgerIcon";
import { MainMenuView } from "./MainMenuView";

import "./DrawerNavigation.scss";
import { SettingsMenuView } from "./SettingsMenuView";
import { IFirebaseUser } from "@modules/users/stores/auth-store";

interface NavigationViewProps {
  user: IFirebaseUser | null;
  onLogout: () => void;
}

const DrawerNavigationView: React.FunctionComponent<NavigationViewProps> = ({
  user,
  onLogout,
}) => {
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const closeDrawer = () => {
    setVisible(false);
  };

  return (
    <header className={"drawer-navigation"}>
      <Row justify="space-between" align="bottom">
        <Col>
          <HamburgerIcon onClick={showDrawer} />
        </Col>

        <Col>
          <SettingsMenuView user={user} onLogout={onLogout} />
        </Col>
      </Row>

      <Drawer
        title="Metadevelopment Dashboard"
        placement="left"
        closable={true}
        onClose={closeDrawer}
        visible={visible}
      >
        <MainMenuView orientation="vertical" onLinkClick={closeDrawer} />
      </Drawer>
    </header>
  );
};

export default DrawerNavigationView;
