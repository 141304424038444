import React from "react";

import "./ToolsScreen.scss";
import { SearchDomain } from "@modules/search/components/SearchDomain";
import SearchView from "@modules/search/components/SearchView";
import { Typography } from "antd";

const ToolsScreen = () => (
  <div className={"tools-screen"}>
    <SearchDomain>{(props) => <SearchView {...props} />}</SearchDomain>

    <div className="tools-screen-links">
      <div>
        <Typography.Title>Metadevelopment tools</Typography.Title>

        <ul>
          <li>
            <a href="https://mylevel.dev" target="_blank" rel="noopener noreferrer">
              {" "}
              <span role="img" aria-label="emodji">🎯</span> Metadevelopment My Level
            </a>
          </li>

          <li>
            <a
              href="https://docs.google.com/spreadsheets/d/1UjkGvxr5W61WhmKL7x7GuSgwGYnwInn9Yf0W5Yoo8jQ/edit?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <span role="img" aria-label="emodji">💼</span> Job Search Marix
            </a>
          </li>
          <li>
            <a
              href="https://docs.google.com/spreadsheets/d/1jHopvNsoW8zPNbNEstpMnfO0unwETwp3KXbO-4iR3Ek/edit?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <span role="img" aria-label="emodji">🤯</span> Mood Analysis
            </a>
          </li>
          <li>
            <a
              href="https://docs.google.com/spreadsheets/d/1RMYRArxM35wsNQDAAyKqHae0_wXIULw3F7WtSKxjgz4/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span role="img" aria-label="emodji">🔎</span> Self Analysis{" "}
            </a>
          </li>
          <li>
            <a
              href="https://docs.google.com/spreadsheets/d/1-rhYtPqkGxOopFwCurjgPsE98SidhmQ1NNffz4z0wMk/edit#gid=0"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span role="img" aria-label="emodji">📊</span> Time Audit sheet
            </a>
          </li>
          <li>
            <a
              href="https://docs.google.com/spreadsheets/d/1L2Oyf0XIucLQnDS3GR6A8MVESVmGaoJg2oZs8edEBWM/edit?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span role="img" aria-label="emodji">⏰</span>Morning Routine Builder
            </a>
          </li>
          <li>
            <a
              href="https://docs.google.com/spreadsheets/d/16Kw2Q5UMLG6og0ATQwl6CzieHfVigwjmz6h0XJD_zFM/edit#gid=0"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span role="img" aria-label="emodji">🧠</span> Neurohacks
            </a>
          </li>
        </ul>
      </div>

      <div>
        <Typography.Title>Community tools</Typography.Title>

        <ul>
          <li>
            <a href="https://github.com/mtdvio/every-programmer-should-know">
              <span role="img" aria-label="emodji">🚀</span> Every Progammer Should know
            </a>
          </li>
          <li>
            <a href="https://github.com/mtdvio/going-to-production">
              <span role="img" aria-label="emodji">🚀</span> Going to prodiction
            </a>
          </li>
          <li>
            <a href="https://github.com/mtdvio/web-development-resources">
              <span role="img" aria-label="emodji">🚀</span> Github tools for front-end engineer
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default ToolsScreen;
