import { types, Instance, getRoot } from "mobx-state-tree"
import { IRootStore } from "@app/rootStore";
import { indexEntityByKey } from "@utils/index";
import { LiveRef, liveReference } from "@utils/liveReference";
import { IPost, Post } from "./posts-store";

import { baseStoreActions } from "@utils/store"
import { fetchCollectionsOp } from "../api/content-api"
import { modifyCommitmentOp } from "@modules/focus/api/focus-api";
import { ICommitment } from "@modules/focus/stores/commitments-store";

export const Collection = types.model("Collection", {
    _type: types.optional(types.literal('collection'), 'collection'),
    id: types.identifierNumber,
    mtdv_id: types.string,
    title: types.string,
    preview_description: types.maybeNull(types.string),
    preview_image: types.maybeNull(types.string),
    content: types.maybeNull(types.string),
    enabled: types.boolean,
    // posts: liveReferenceArray(Post),
    posts: types.array(liveReference(Post)),
    access_level: types.string,
    preview_colour: types.string,
    created_at: types.string,
    updated_at: types.string,
})

// const coll = Collection.create({ id: 1, mtdv_id: '', title: '', enabled: true, posts: [], access_level: '', preview_colour: '', created_at: '', updated_at: '' })
// const post = resolve(coll.posts)

export interface ICollection extends Instance<typeof Collection> { }

const storeName = "CommitmentStore"
export const CollectionStore = types
    .model(storeName, {
        _byId: types.map(Collection)
    })
    .views((self) => ({
        get root(): IRootStore {
            return getRoot(self)
        },
        byId(id: number) {
            return self._byId.get(id.toString())
        },
        get byMtdvId() {
            return indexEntityByKey('mtdv_id', Array.from(self._byId.values()))
        },
        find(id: number | LiveRef<ICollection>) {
            return typeof id === 'number'
                ? this.byId(id)
                : id.ref
        },
        collectionPosts(id: number): IPost[] {
            const collection = this.byId(id)
            if (!collection) return []

            return collection
                .posts
                .map(p => p!.ref)
                .filter(p => Boolean(p)) as IPost[]
        }
    }))
    .actions((self) => {
        return {
            ...baseStoreActions<typeof Collection, ICollection>(storeName, self.root, self._byId),
            ...{
                fetch() {
                    const op = fetchCollectionsOp();
                    self.root.networkSystem.execute(op)

                    this.trackSuccessOp(op.id, (sucessOp) => {
                        this.setAll(sucessOp.data)
                        self.root.searchStore.setEntities("Collection", sucessOp.data)
                    })
                },
                commit: function (id: number) {
                    const collection = self._byId.get(id.toString())
                    if (collection) {
                        const op = modifyCommitmentOp(collection.id, "collections", "commit");
                        self.root.networkSystem.execute(op)

                        this.trackSuccessOp(op.id, (sucessOp) => {
                            this.updateCommitment(sucessOp.data)
                        })
                    }
                },
                reset: function (id: number) {
                    const collection = self._byId.get(id.toString())
                    if (collection) {
                        const op = modifyCommitmentOp(collection.id, "collections", "reset");
                        self.root.networkSystem.execute(op)

                        this.trackSuccessOp(op.id, (sucessOp) => {
                            this.updateCommitment(sucessOp.data)
                        })

                        // // mst can freak out when schema is not matching
                        // const commitment = self.root.commitments.byCollection[collection.id];
                        // self.root.commitments.put(cast({ ...commitment, status: updatedCommitment.status }))
                    }
                },
                finish: function (id: number) {
                    const collection = self._byId.get(id.toString())
                    if (collection) {
                        const op = modifyCommitmentOp(collection.id, "collections", "finish");
                        self.root.networkSystem.execute(op)

                        this.trackSuccessOp(op.id, (sucessOp) => {
                            this.updateCommitment(sucessOp.data)
                        })

                        // // mst can freak out when schema is not matching
                        // const commitment = self.root.commitments.byCollection[collection.id];
                        // self.root.commitments.put(cast({ ...commitment, status: updatedCommitment.status }))
                    }
                },
                updateCommitment(updatedCommitment: ICommitment) {
                    self.root.commitments.set(updatedCommitment)
                }
            }
        }
    });

export interface ICollectionStore extends Instance<typeof CollectionStore> { }
(window as any).Collection = Collection