import { types, getRoot, Instance } from "mobx-state-tree"
import { nanoid } from 'nanoid'
import { IRootStore } from "../../rootStore";
import Noty, { Type as NotificationLevel } from "noty";

const Notification = types.model('Notificaton', {
    id: types.identifier,
    level: types.optional(types.union(
        types.literal("alert"),
        types.literal("error"),
        types.literal("warning"),
        types.literal("success"),
        types.literal("info"),
        types.literal("information"),
    ), "info"),
    message: types.string,
}).views(self => ({
    get root(): IRootStore {
        return getRoot(self)
    }
}))

type CreateNotificationData = {
    level: NotificationLevel,
    message: string,
    // position, time, type?
}

export interface INotification extends Instance<typeof Notification> { }

export function createNotification(data: CreateNotificationData): INotification {
    const { level, message } = data;
    const id = `net-${nanoid(4)}`
    return Notification.create({
        id: id,
        level: level,
        message: message,
    })
}

export const NotificationsStore = types.model('NotificationsStore', {
    notifications: types.array(Notification)
})
    .actions(self => ({
        push: function push(notification: INotification) {
            // we can show it in list on header
            self.notifications.push(notification)

            // using noty by default
            new Noty({
                theme: "mint",
                type: notification.level,
                layout: "topRight",
                text: notification.message,
                timeout: 3000,
                progressBar: true,
                closeWith: ["click"],
            }).show();
        },
        success: function (message: string) {
            this.push(createNotification({
                level: "error",
                message
            }))
        },
        info: function (message: string) {
            this.push(createNotification({
                level: "info",
                message
            }))
        },
        error: function (message: string) {
            this.push(createNotification({
                level: "error",
                message
            }))
        }
    }))