import React from "react";
import classnames from "classnames";
import { Card } from "antd";
import { Link } from "react-router-dom";
import { CurrentCollectionPostView } from "./CurrentCollectionPostView";

import "./FocusCollectionCardView.scss";
import { StatusDate } from "@components/common/status-date/StatusDate";
import { ICommitment } from "@modules/focus/stores/commitments-store";
import { observer } from "mobx-react-lite";

import "./FocusCard.scss";
import { useMst } from "@app/index";

interface FocusCollectionCardViewProps {
  id: ICommitment['id'];
}

const Component: React.FunctionComponent<FocusCollectionCardViewProps> = ({ id }) => {
  const { commitments } = useMst()
  const collection = commitments.collectionById(id)
  const currentCollectionPost = commitments.currentCollectionPostById(id)
  if (!collection) return null

  const cls = classnames("focus-collection-card");

  return (
    <Card
      className={cls}
      title={
        <>
          <Link to={`/library/collections/${collection?.mtdv_id}`}>
            {collection?.title}
          </Link>
        </>
      }
      actions={
        [
          // icon
        ]
      }
      headStyle={{ backgroundColor: collection?.preview_colour }}
    >
      {currentCollectionPost ? (
        <CurrentCollectionPostView post={currentCollectionPost} />
      ) : null}

      <StatusDate id={id} />
    </Card>
  );
};

export const FocusCollectionCard = observer(Component)