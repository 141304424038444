import React from "react";
import LoginFormDomain from "./LoginFormDomain";

export default function SignInSide() {
  return (
    <div className="login-screen">
      <LoginFormDomain />
    </div>
  );
}
