import { IFirebaseUser } from "@modules/users/stores/auth-store";
import { Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";

import "./MainMenuView.scss";

type NavMenuViewProps = {
  user: IFirebaseUser | null;
  onLogout: () => void;
};

export const SettingsMenuView: FunctionComponent<NavMenuViewProps> = ({
  user,
  onLogout,
}) => {
  return (
    <Menu mode="horizontal" className={"mtdv-nav-menu"}>
      <SubMenu title={user?.email}>
        <Menu.Item>
          <Link to={"/settings"}>Settings</Link>
        </Menu.Item>
        <Menu.Divider></Menu.Divider>
        <Menu.Item onClick={() => onLogout()}>Logout</Menu.Item>
      </SubMenu>
    </Menu>
  );
};
