export function indexEntityByKey<T extends { [key: string]: any }>(
  key: string,
  notIndexedEntities: T[]
): { [key: string]: T } {
  return notIndexedEntities.reduce((acc, p) => {
    let idKey = p[key]

    if (!idKey) {
      return acc;
    }

    if (idKey.id !== undefined) {
      idKey = idKey.id
    }

    if (idKey) {
      acc[idKey] = p;
    }

    return acc;
  }, {} as { [key: string]: T });
}

export function indexEntitesByKey<T extends { [key: string]: any }>(
  key: string,
  notIndexedEntities: T[]
): { [key: string]: T[] } {
  return notIndexedEntities.reduce((acc, p) => {
    let idKey = p[key]
    if (!idKey) {
      return acc;
    }

    if (idKey.id !== undefined) {
      idKey = idKey.id
    }

    if (!acc[idKey]) {
      acc[idKey] = [p];
    } else {
      acc[idKey].push(p)
    }
    return acc;
  }, {} as { [key: string]: T[] });
}

export function viewMetrics<T>(callee: string, fn: () => T): T {
  // eslint-disable-next-line
  console.time(`${callee}`)
  const res = fn()

  // eslint-disable-next-line
  console.timeEnd(`${callee}`)
  return res
}