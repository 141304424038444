import React from "react";
import "./FocusCard.scss";
import { Card, Typography } from "antd";
import { FolderAddOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";

import "./PlaceholderFocusCard.scss";

interface PlaceholderFocusCardProps {}

export const PlaceholderFocusCard: React.FunctionComponent<PlaceholderFocusCardProps> = () => {
  const history = useHistory();

  return (
    <Card
      className="placeholder-focus-card"
      onClick={() => history.push("/courses")}
      actions={
        [
          // icon
        ]
      }
    >
      <div className="placeholder-focus-card__content">
        <FolderAddOutlined className="placeholder-focus-card__content__icon"></FolderAddOutlined>
        <Typography.Text className="placeholder-focus-card__content__label">
          Add Focus
        </Typography.Text>
      </div>
    </Card>
  );
};
