import { getRoot, IAnyModelType, IModelType, IMSTArray, Instance, ISimpleType, ISnapshotProcessor, resolveIdentifier, types, _NotCustomized } from "mobx-state-tree";

// This represents a reference to another type
// Example:
// types.model("Collection", {
//   posts: types.array(liveReference(Post)),
// })
//
// Warning: this function MUST have a full return type specified for type inference to work in resolve() calls
export function liveReference<T extends IAnyModelType>(model: T, refType: ISimpleType<string> | ISimpleType<number> = types.number): ISnapshotProcessor<LiveRefModel<T>, number | { id: number; }, string | number | null> {
  const refModel =
    types.model({
      // should be the same as identifier Type of the referenced model
      id: refType
    })
      .volatile((self) => ({
        _refId: `${model.name}Ref-${self.id}`
      }))
      .views((self: any) => ({
        get ref(): Instance<T> | undefined {
          try {
            return resolveIdentifier(model, getRoot(self), self.id);
          } catch {
            return undefined;
          }
        }
      }))
      .named(`${model.name}Ref`)

  return types.snapshotProcessor(refModel, {
    preProcessor(snapshot: number | { id: number }) {
      if (typeof snapshot === "object") {
        return snapshot;
      }

      return {
        id: snapshot
      };
    },
    postProcessor(snapshot) {
      return snapshot ? snapshot.id : null;
    }
  });
}


export function liveReferenceArray<T extends IAnyModelType>(model: T, refType: ISimpleType<string> | ISimpleType<number> = types.number) {
  const refArrModel = types.array(liveReference<T>(model, refType));

  return types.snapshotProcessor(refArrModel, {
    preProcessor(snapshot: (number | { id: number })[]) {
      if (Array.isArray(snapshot)) {
        return snapshot;
      }

      return []
    },
    postProcessor(snapshot) {
      return snapshot.length > 0 ? snapshot : [];
    }
  });
}

// force reference resolution on singular reference on on reference array
export function resolve<T extends IAnyModelType>(obj: LiveRef<Instance<T>> | LiveRefArray<T> | null): Instance<T> | Instance<T>[] | undefined {
  if (obj === null) return undefined
  return Array.isArray(obj)
    ? resolveArr(obj)
    : obj.ref
}

// resolves the types.array(liveReference<T>)
// do not use directly, use resolve(arr)
export function resolveArr<T extends IAnyModelType>(obj: LiveRefArray<T>): Instance<T>[] {
  return obj.filter(Boolean).map(i => i!.ref).filter(Boolean) as Instance<T>[]
}

// This type represents and Instance<refModel> of reference, which resolves to Instance<ModelType> of the referenced model
export type LiveRef<T extends Instance<IAnyModelType>> =
  Instance<
    IModelType<
      { id: ISimpleType<string> | ISimpleType<number>; },
      { _refId: string; } & { readonly ref: T | undefined; },
      _NotCustomized,
      _NotCustomized
    >
  >

type LiveRefModel<T extends IAnyModelType> =
  IModelType<
    { id: ISimpleType<string> | ISimpleType<number>; },
    { _refId: string; } & { readonly ref: Instance<T> | undefined; },
    _NotCustomized,
    _NotCustomized
  >

export type LiveRefArray<T extends IAnyModelType> =
  IMSTArray<
    ISnapshotProcessor<
      LiveRefModel<T>,
      number | { id: number; }, // this is important
      string | number | null // this is also important
    >
  >