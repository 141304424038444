import Fuse from "fuse.js";
import { getRoot, types } from "mobx-state-tree";
import { IRootStore } from "@app/rootStore";
import { autorun } from "mobx";

export type SearchEntityType = "Collection" | "Post" | "Commitment";

const options = {
    // isCaseSensitive: false,
    // includeScore: false,
    // shouldSort: true,
    // includeMatches: false,
    // findAllMatches: false,
    // minMatchCharLength: 1,
    // location: 0,
    // threshold: 0.6,
    // distance: 100,
    // useExtendedSearch: false,
    // ignoreLocation: false,
    // ignoreFieldNorm: false,
    keys: ["title", "tags"],
};

export interface SearchableEntity {
    id: number;
    mtdv_id: string,
    title: string;
    type: SearchEntityType;
}

interface SearchBootChecklist {
    [key: string]: boolean;
}

export const SearchableEntity = types.model('SearchableEntity', {
    id: types.identifierNumber,
    title: types.string,
    mtdv_id: types.string,
    type: types.union(
        types.literal("Collection"),
        types.literal("Post"),
        types.literal("Commitment"),
    )
})

export const SearchStore = types.model('SearchStore', {
    searchResults: types.array(SearchableEntity),
    searchPattern: types.maybeNull(types.string),
    fuse: types.maybeNull(types.frozen<Fuse<SearchableEntity>>()),
}).views(self => ({
    get root(): IRootStore {
        return getRoot(self)
    },
    matchedEntities(type: SearchEntityType): SearchableEntity[] {
        return self.searchResults.filter((it) => it.type === type)
    }
})).actions(self => {
    let bootChecklist: SearchBootChecklist = {}
    return ({
        afterCreate() {
            self.fuse = new Fuse<SearchableEntity>([], options);
        },
        add(id: number, mtdv_id: string, type: SearchEntityType, title: string) {
            self.fuse!.add({
                id: id,
                mtdv_id: mtdv_id,
                type: type,
                title: title
            } as SearchableEntity);
        },
        setEntities(type: SearchEntityType, entities: SearchableEntity[]): void {
            // hack! we need this check to ensure that entiries will be created once
            if (!bootChecklist[type] && entities.length > 0) {
                entities.forEach((e) => {
                    self.fuse!.add({
                        id: e.id,
                        mtdv_id: e.mtdv_id,
                        type: type,
                        title: e.title
                    });
                });
                bootChecklist[type] = true;
            }
        },
        search(pattern: string): SearchableEntity[] {
            self.searchPattern = pattern;
            const matches = self.fuse!.search(self.searchPattern);
            self.searchResults.replace(matches.map((it) => it.item));
            return self.searchResults;
        },
        clear() {
            self.searchResults.clear();
            self.searchPattern = null;
        }
    })
})