import {
  BookOutlined,
  SlackSquareOutlined,
  ThunderboltFilled,
} from "@ant-design/icons";
import { Menu } from "antd";
import { MenuMode } from "antd/lib/menu";
import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";

import "./MainMenuView.scss";

type NavMenuViewProps = {
  orientation: MenuMode;
  onLinkClick?: () => void;
};

export const MainMenuView: FunctionComponent<NavMenuViewProps> = ({
  orientation,
  onLinkClick,
}) => {
  return (
    <Menu mode={orientation} className={"mtdv-nav-menu"}>
      <Menu.Item key="focus" icon={<ThunderboltFilled />}>
        <Link to={"/"} onClick={onLinkClick}>
          Focus
        </Link>
      </Menu.Item>
      <Menu.Item key="courses" icon={<BookOutlined />}>
        <Link to={"/courses"} onClick={onLinkClick}>
          Courses
        </Link>
      </Menu.Item>
      <Menu.Item key="resources" icon={<BookOutlined />}>
        <Link to={"/resources"} onClick={onLinkClick}>
          Resources
        </Link>
      </Menu.Item>
      <Menu.Item key="quests" icon={<BookOutlined />}>
        <Link to={"/quests"} onClick={onLinkClick}>
          Quests
        </Link>
      </Menu.Item>
      <Menu.Item key="tools" icon={<BookOutlined />}>
        <Link to={"/tools"} onClick={onLinkClick}>
          Tools
        </Link>
      </Menu.Item>
      <Menu.Item key="slack_community" icon={<SlackSquareOutlined />}>
        <a
          href={"https://mtdv.slack.com/"}
          target="__blank"
          onClick={onLinkClick}
        >
          Slack community
        </a>
      </Menu.Item>
    </Menu>
  );
};
