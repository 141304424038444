import React from "react";
import { Button } from "antd";
import "./StatusButton.scss";

interface StatusActionButtonProps {
  title: string;
  cls: string;
  onClick: () => void;
}

export const StatusButton: React.FunctionComponent<StatusActionButtonProps> = ({
  title,
  cls,
  onClick,
}) => {
  return (
    <Button
      key="1"
      type="primary"
      onClick={() => onClick()}
      className={"status-button " + cls}
    >
      {title}
    </Button>
  );
};
