import React from "react";
import "./FocusCard.scss";

import { FocusPostCardView } from "./FocusPostCard";
import { FocusCollectionCard } from "./FocusCollectionCard";
import { ICommitment } from "@modules/focus/stores/commitments-store";
import { useMst } from "@app/index";

interface FocusCardProps {
  id: ICommitment['id'];
}

export const FocusCard: React.FunctionComponent<FocusCardProps> = ({ id }) => {
  const { commitments } = useMst()
  const commitment = commitments.byId(id)
  return <div className="focus-card">{concreteCard(commitment)}</div>;
};

const concreteCard = (commitment?: ICommitment) => {
  if (!commitment) {
    return null
  }

  if (commitment.type === "collection") {
    return (
      <FocusCollectionCard
        id={commitment.id}
      />
    );
  } else if (commitment.type === "post") {
    return <FocusPostCardView id={commitment.id} />;
  } else {
    return null;
  }
};
