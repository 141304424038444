import { types, Instance, getRoot } from "mobx-state-tree"
import { IRootStore } from "@app/rootStore";
import { Collection } from "@modules/content/stores/collections-store";
import { Post } from "@modules/content/stores/posts-store";
import { indexEntityByKey } from "@utils/index";
import { liveReference } from "@utils/liveReference";
import { fetchCommitmentsOp } from "../api/focus-api";
import { baseStoreActions } from "@utils/store";

export const Commitment = types.model("Commitment", {
    id: types.identifierNumber,
    type: types.string,
    status: types.string,
    user_id: types.string,
    committed_date: types.maybeNull(types.string),
    finished_date: types.maybeNull(types.string),
    post: types.maybeNull(liveReference(Post)),
    collection: types.maybeNull(liveReference(Collection)),
    current_collection_post: types.maybeNull(liveReference(Post))
})
    .views(self => ({
        get root(): IRootStore {
            return getRoot(self)
        }
    }))

export interface ICommitment extends Instance<typeof Commitment> { };

const MaxFocusItems = 5;

const storeName = "CommitmentStore"
export const CommitmentStore = types
    .model(storeName, {
        _byId: types.map(Commitment)
    })
    .views(self => ({
        get root(): IRootStore {
            return getRoot(self)
        },
        byId(id: number) {
            return self._byId.get(id.toString())
        },
        collectionById(id: number) {
            return this.byId(id)?.collection?.ref
        },
        currentCollectionPostById(id: number) {
            return this.byId(id)?.current_collection_post?.ref
        },
        postById(id: number) {
            return this.byId(id)?.post?.ref
        },
        get array() {
            return Array.from(self._byId.values())
        },
        get byCollection() {
            return indexEntityByKey('collection', this.array)
        },
        get byPost() {
            return indexEntityByKey('post', this.array)
        },
        get committedOnly() {
            return this.array.filter(c => c.status === "committed")
        },
        get focusCommitments() {
            return this.committedOnly
        },
        get focusPlaceholderCount() {
            return MaxFocusItems - this.committedOnly.length;
        },
        get hasFocusSlots() {
            return MaxFocusItems - this.committedOnly.length > 0;
        }
    }))
    .actions(self => ({
        ...baseStoreActions<typeof Commitment, ICommitment>(storeName, self.root, self._byId),
        ...{
            fetch: function () {
                try {
                    const op = fetchCommitmentsOp();
                    self.root.networkSystem.execute(op)

                    this.trackSuccessOp(op.id, (sucessOp) => {
                        this.setAll(sucessOp.data)
                        console.info('Commitments fetched')
                    })

                } catch (e) {
                    console.error('failed to fetch commitments', e)
                }
            }
        }
    }))
