import { types, getRoot, flow, Instance, unprotect } from "mobx-state-tree"
import { nanoid } from 'nanoid'
import firebase from "firebase";
import { IRootStore } from "../../rootStore";
import { SearchEntityType } from "@systems/search/search-system";
import { indexEntitesByKey, indexEntityByKey, viewMetrics } from "@utils/index";
import { autorun } from "mobx";

const NetworkOperationBody = types.frozen();
const NetworkOperationData = types.frozen();
const NetworkOperationError = types.frozen();

const NetworkOperation = types.model('NetworkOperation', {
    // Unique globally (nanoid)
    id: types.identifier,
    operation: types.string,
    entityType: types.string,
    entityId: types.string,
    url: types.string,
    method: types.string,
    auth: types.boolean,
    body: NetworkOperationBody,
    status: types.optional(types.union(
        types.literal("idle"),
        types.literal("in_progress"),
        types.literal("success"),
        types.literal("error")
    ), "idle"),
    data: types.frozen(NetworkOperationData),
    error: types.maybe(NetworkOperationError)
}).views(self => ({
    get root(): IRootStore {
        return getRoot(self)
    }
}))

export type RawOperationStatus = 'getAll' | 'getOne' | 'create' | 'update' | 'delete'
export type RawOperations = 'getAll' | 'getOne' | 'create' | 'update' | 'delete'
export type RawEntityId = '*' | number

type RawOperationType<T> = {
    operation: RawOperations,
    entityType: string,
    entityId: RawEntityId,
    url: string,
    method: string,
    auth: boolean,
    body: T | undefined
}

export function createNetworkOperation<T>({
    operation,
    url,
    entityType,
    entityId,
    method,
    auth,
    body
}: RawOperationType<T>): INetworkOperation {
    const id = `net-${nanoid(4)}`
    return NetworkOperation.create({
        id: id,
        operation: operation!,
        entityType: entityType,
        entityId: entityId.toString(),
        url: url!,
        method,
        auth,
        body: NetworkOperationBody.create(body),
        status: "idle",
        data: undefined,
        error: undefined
    })
}

export interface INetworkOperation extends Instance<typeof NetworkOperation> { }


interface NetworkOpsCache {
    [entityType: string]: {
        [entityId: string]: {
            [operationType: string]: INetworkOperation | undefined
        }
    }
}
/*
{
    "post": {
        "*": { "getAll": {} },
        42: { "update": {} }
    }
}

*/

// entity -> entityId -> operation 

// perf improvement - 
export const NetworkOperationStore = types.model('NetworkOperationStore', {
    byId: types.map(NetworkOperation)
})
    .views(self => ({
        get networkOpsCache(): NetworkOpsCache {
            return viewMetrics("networkOpsCache", () => Array.from(self.byId.values()).reduce((acc, op) => {
                if (!acc[op.entityType]) {
                    acc[op.entityType] = {}
                }

                if (!acc[op.entityType][op.entityId]) {
                    acc[op.entityType][op.entityId] = {}
                }

                // TODO we might want to have have ops array here
                acc[op.entityType][op.entityId][op.operation] = op

                return acc;
            }, {} as NetworkOpsCache))
        },
        findBy(enityType: string, entityId?: RawEntityId, operationType?: RawOperations): INetworkOperation | undefined {
            const byEntityType = this.networkOpsCache[enityType];
            if (!byEntityType) {
                return undefined
            }

            const byEntityId = byEntityType[entityId || '*']
            if (!byEntityId) {
                return undefined
            }

            return byEntityId[operationType || 'getAll']
        }
    }))
    .actions(self => {
        return {
            afterCreate() {
                autorun(() => {
                    let noop = undefined
                    noop = self.networkOpsCache;
                })
            },
            register(operation: INetworkOperation) {
                self.byId.set(operation.id, operation)
            },
            execute: flow(function* (op: INetworkOperation) {
                self.byId.set(op.id, op)

                const headers: { [h: string]: string } = {
                    'Content-Type': 'application/json'
                }

                const opLogLine = `${op.operation}[${op.entityId}] - [${op.method}] ${op.url}  auth:${op.auth}`
                try {
                    if (op.auth) {
                        const token = yield getCurrentUserAccessToken()
                        headers['Authorization'] = `Bearer ${token}`
                    }

                    const config: any = {
                        method: op.method,
                        mode: 'cors',
                        cache: 'no-cache',
                        credentials: 'omit',
                        headers: headers,
                    }

                    if (op.body) {
                        config.body = JSON.stringify(op.body);
                    }

                    op.status = 'in_progress'

                    console.time(opLogLine)
                    const response = yield fetch(op.url, config)

                    // look at status look for network systems
                    // 401/403 - general auth errors
                    // 500 - internal server error/???
                    // 400 - invalid data 
                    const data = yield response.json()
                    op.data = data;

                    if (response.status < 300) {
                        op.status = 'success'
                        console.debug(`[${op.status}] - ${op.operation}[${op.entityId}] - [${op.method}] ${op.url}`)
                    } else {
                        throw new Error(`API-Error - status ${response.status} ${response.message}`);
                    }
                } catch (err) {
                    op.error = err;
                    op.status = 'error'
                    console.error(`[${op.status}] - ${op.operation}[${op.entityId}] - [${op.method}] ${op.url}`, err)
                } finally {
                    self.byId.set(op.id, op)
                    console.timeEnd(opLogLine)
                }
            }),
        }
    })

async function getCurrentUserAccessToken() {
    try {
        let currentUser = firebase.auth().currentUser;
        if (currentUser) {
            return await currentUser.getIdToken();
        } else {
            throw new Error("No active user available");
        }
    } catch (e) {
        console.error("Failed to fetch firebase token for current user", e);
        throw e;
    }
}
