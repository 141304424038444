import React from "react";
import { observer } from "mobx-react-lite";
import { useMst } from "@app/index";
import { Redirect, Route, RouteProps } from "react-router";

const PrivateRoute: React.FunctionComponent<RouteProps> = ({
  children,
  ...rest
}) => {
  const { authStore } = useMst();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        authStore.isLoggedIn ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
      }
    />
  );
};

export default observer(PrivateRoute);
